@import '../shared/variables.scss';

.Lock {
    position: relative;
    pointer-events: none;
    height: 35px;
    width: 60px;
    .Lock_area {
        position: absolute;
        height: 35px;
        width: 35px;
        top: 0;
        left: 0;
        border-radius: 35px;
        background-color: $color-brand;
        transition: all 300ms ease;
        .Lock_status {
            position: absolute;
            width: 25px;
            height: 25px;
            top: 5px;
            left: 5px;
            border-radius: 30px;
            background-color: $color-brand;
            transition: all 300ms ease;
            pointer-events: none;
        }
        .Lock_open {
            position: absolute;
            width: 25px;
            height: 25px;
            top: 5px;
            left: 5px;
            pointer-events: none;
            transition: all 300ms ease;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 13px auto;
                background-image: url('/images/unlock.svg');
                transition: all 300ms ease;
                filter: invert(1);
                opacity: 1;
            }
        }
        .Lock_close {
            position: absolute;
            width: 25px;
            height: 25px;
            top: 5px;
            right: 5px;
            pointer-events: none;
            transition: all 300ms ease;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 13px auto;
                background-image: url('/images/lock.svg');
                transition: all 300ms ease;
                filter: invert(1);
                opacity: 0;
            }
        }
    }
    &.Lock_overlay {
        pointer-events: all;
        .Lock_area {
            width: 60px;
            .Lock_status {
                background-color: $color-white;
            }
            .Lock_open:after {
                filter: invert(0);
            }
            .Lock_close:after {
                opacity: 1;
            }
        }
    }
    &.Lock_locked .Lock_area {
        .Lock_status {
            left: 30px;
        }
        .Lock_open:after {
            filter: invert(1);
        }
        .Lock_close:after {
            filter: invert(0);
        }
    }
}
