@import '../shared/variables.scss';

.Category {
    position: relative;
    .Category_tiles {
        position: relative;
        margin: -10px;
        .Category_tile {
            position: relative;
            padding: 10px;
            .Category_notification {
                position: relative;
                background-color: #fff5e1;
                padding: 30px;
                font-size: 14px;
                line-height: 1.4em;
                color: #a38851;
            }
        }
    }
}
