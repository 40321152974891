@import '../shared/variables.scss';

.Splash {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: all;
    background-color: $color-white;
    transition: opacity 500ms ease-out;
    .Splash_image {
        position: absolute;
        width: 80%;
        height: 80%;
        left: 10%;
        top: 10%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &.Splash_done {
        opacity: 0;
        pointer-events: none;
    }
}
