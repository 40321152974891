@import '../shared/variables.scss';

.Onboarding {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms ease-out;
    background-color: $color-white;
    .Onboarding_canvas {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-white;
        .Onboarding_description {
            position: absolute;
            width: 100%;
            height: 50px;
            left: 0;
            top: 40px;
            p {
                line-height: 60px;
                font-size: 15px;
                text-align: center;
                font-weight: 600;
                color: $color-text-dark;
            }
        }
        .Onboarding_button {
            position: absolute;
            left: 60px;
            right: 60px;
            height: 50px;
            bottom: 40px;
            background-color: #eeeeee;
            border-radius: 6px;
            cursor: pointer;
            &:active {
                background-color: #dddddd;
            }
            p {
                line-height: 50px;
                font-size: 14px;
                text-align: center;
                font-weight: 400;
                color: $color-text-dark;
            }
        }
        .Onboarding_device {
            position: absolute;
            width: 330px;
            height: 610px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50.8%) scale(1);
            @media only screen and (max-width: 375px) {
                transform: translate(-50%, -50.8%) scale(0.75);
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-image: url('/images/overlay.png');
            }
            .Onboarding_screen {
                position: absolute;
                top: 46px;
                bottom: 28px;
                left: 27px;
                right: 27px;
                background-repeat: no-repeat;
                background-position: center top;
                background-size: contain;
            }
        }
    }
    &.Onboarding_active {
        opacity: 1;
        pointer-events: all;
    }
}
