@import '../shared/variables.scss';

.Tile {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $color-background;
    padding: 30px;
    .Tile_bookmark {
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: 30px;
        .Tile_icon {
            position: relative;
            width: 30px;
            height: 30px;
            background-color: $color-grey;
            border-radius: 100%;
            transition: all 300ms ease-out;
            .Tile_line {
                content: '';
                position: absolute;
                width: 10px;
                height: 2px;
                background-color: $color-white;
                border-radius: 2px;
                top: 14px;
                left: 10px;
                transition: all 300ms ease-out;
                &:last-child {
                    transform: rotate(90deg);
                }
            }
        }
        &.Tile_active .Tile_icon {
            background-color: $color-brand;
            .Tile_line:last-child {
                transform: rotate(0deg);
            }
        }
    }
    .Tile_new {
        position: absolute;
        top: 0;
        right: -10px;
        background-color: #f4bf56;
        transform: translateY(-50%);
        height: 20px;
        border-radius: 20px;
        line-height: 20px;
        font-size: 9px;
        padding: 0 10px;
        color: $color-white;
        font-weight: 600;
        opacity: 0;
        transition: all 300ms ease-out;
        &.Tile_visible {
            opacity: 1;
        }
    }
    .Tile_information {
        position: relative;
        flex-shrink: 1;
        flex-grow: 1;
        h3 {
            font-size: 15px;
            line-height: 1.2em;
            font-weight: 400;
        }
        p {
            font-size: 13px;
            line-height: 1.4em;
            color: $color-text-light;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &.Tile_category {
        padding-right: 60px;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 30px;
            width: 8px;
            height: 12px;
            transform: translateY(-50%);
            background-image: url('/images/arrow.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }
    &:active {
        background-color: darken($color-background, 2%);
    }
    &.Tile_handle {
        padding-left: 80px;
    }
    &.Tile_has_icon {
        padding-left: 80px;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 30px;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }
    &.Tile_icon_algemeen:before {
        background-image: url('/images/icon-algemeen.svg');
    }
    &.Tile_icon_brandwond:before {
        background-image: url('/images/icon-brandwond.svg');
    }
    &.Tile_icon_ge:before {
        background-image: url('/images/icon-ge.svg');
    }
    &.Tile_icon_oncologie:before {
        background-image: url('/images/icon-oncologie.svg');
    }
    &.Tile_icon_thorax:before {
        background-image: url('/images/icon-thorax.svg');
    }
    &.Tile_icon_trauma:before {
        background-image: url('/images/icon-trauma.svg');
    }
    &.Tile_icon_vaat:before {
        background-image: url('/images/icon-vaat.svg');
    }
}
