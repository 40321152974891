@import '../shared/variables.scss';

.Bookmarks {
    position: relative;
    .Bookmarks_count {
        position: relative;
        margin-bottom: 20px;
        p {
            font-size: 14px;
            color: $color-text-medium;
        }
    }
    .Bookmarks_tiles {
        position: relative;
        .Bookmarks_tile {
            position: relative;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .Bookmarks_drag {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 10px;
                left: 10px;
                bottom: 10px;
                width: 80px;
                opacity: 0.25;
                cursor: pointer;
                .Bookmarks_lines {
                    position: relative;
                    width: 20px;
                    height: 14px;
                    .Bookmarks_line {
                        position: absolute;
                        left: 0;
                        width: 20px;
                        height: 2px;
                        border-radius: 2px;
                        background-color: $color-black;
                        &:nth-child(1) {
                            top: 0;
                        }
                        &:nth-child(2) {
                            top: 6px;
                        }
                        &:nth-child(3) {
                            top: 12px;
                        }
                    }
                }
            }
        }
    }
}
