@import '../shared/variables.scss';

.Cookies {
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    .Cookies_holder {
        position: relative;
        width: 100%;
        padding: 20px;
        .Cookies_window {
            position: relative;
            margin: 0 auto;
            background-color: $color-white;
            max-width: 600px;
            border-radius: 6px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
            border: 1px solid #eeeeee;
            pointer-events: all;
            padding: 20px;
            p {
                font-size: 12px;
                line-height: 1.4em;
                color: $color-text-medium;
                margin-bottom: 10px;
            }
            button {
                background-color: $color-black;
                color: $color-white;
                font-weight: 600;
                padding: 6px 12px;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
}
