@import 'shared/reset.scss';
@import 'shared/fonts.scss';
@import 'shared/variables.scss';

* {
    touch-action: pan-x pan-y;
}
::-webkit-scrollbar {
    display: none;
}
html,
body {
    user-select: none;
    overflow: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    font-family: 'Open Sans', serif;
    font-weight: 300;
    font-size: 14px;
    color: $color-black;
    background-color: $color-black;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4em;
    pointer-events: none;
}
#root {
    position: relative;
    height: 100vh;
}
button,
input,
select,
textarea {
    appearance: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    outline: none;
}
a {
    color: inherit;
    text-decoration: none;
}
p {
    line-height: 1em;
    color: $color-text-medium;
}
h1,
h2,
h3,
h4 {
    line-height: 1em;
    color: $color-text-dark;
}
#root {
    position: relative;
    height: 100vh;
}
div[data-pp-cfiw-widget='ebd9b6fa-7e37-11ea-8efb-06df22ffaf6f'] {
    pointer-events: all;
    #pp-cfpiaw-trigger,
    #pp-cfpiaw-dialog button {
        background-color: $color-black;
    }
    #pp-cfpiaw-dialog a,
    #pp-cfpiaw-dialog textarea:focus ~ label,
    #pp-cfpiaw-dialog input[type='text']:focus ~ label,
    #pp-cfpiaw-dialog input[type='email']:focus ~ label {
        color: $color-black;
    }
    #pp-cfpiaw-dialog textarea:hover,
    #pp-cfpiaw-dialog input[type='text']:hover,
    #pp-cfpiaw-dialog input[type='email']:hover {
        border-color: #acacac;
    }
    .pp-cfpiaw-trigger__icon {
        margin: 0 !important;
    }
    .pp-cfpiaw-trigger__text {
        display: none;
    }
    .pp-cfpiaw__form .pp-cfpiaw-trigger__text {
        display: initial;
    }
}
