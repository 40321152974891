@import '../shared/variables.scss';

.Disclaimer {
    position: relative;
    padding: 20px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    h2 {
        color: $color-text-dark;
        font-weight: 600;
        margin-bottom: 20px;
    }
    p {
        line-height: 1.6em;
        color: $color-text-medium;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            color: $color-text-dark;
            text-decoration: underline;
        }
    }
}
