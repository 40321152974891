@import '../shared/variables.scss';

.Search {
    position: relative;
    .Search_count {
        position: relative;
        margin-bottom: 20px;
        p {
            font-size: 14px;
            color: $color-text-medium;
        }
    }
    .Search_tiles {
        position: relative;
        margin: -10px;
        .Search_tile {
            position: relative;
            padding: 10px;
        }
    }
}
