@import '../shared/variables.scss';

.Article {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    .Article_lazy {
        opacity: 0;
        transition: opacity 300ms ease-out;
        &.Article_visible {
            opacity: 1;
        }
    }
    .Article_loaded {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .Article_visual {
            position: absolute;
            width: 100%;
            padding-bottom: 100%;
            top: 0;
            left: 0;
            @media only screen and (min-width: 769px) {
                width: calc(50% - 90px);
                padding-bottom: calc(50% - 90px);
                left: 60px;
                top: 60px;
            }
            @media only screen and (min-width: 1194px) {
                width: 640px;
                padding-bottom: 640px;
            }
            .Article_slider {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                .Article_container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    .Article_slide {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        flex-grow: 0;
                        flex-shrink: 0;
                        overflow: hidden;
                        cursor: pointer;
                        .Article_zoom {
                            position: relative;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            .Article_image {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                pointer-events: none;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .Article_overlay {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                opacity: 0;
                                transition: all 300ms ease-out;
                                pointer-events: none;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                                .Article_point {
                                    position: absolute;
                                    width: 30px;
                                    height: 30px;
                                    top: 0;
                                    left: 0;
                                    border-radius: 30px;
                                    transform: translate(-50%, -50%);
                                    transition: opacity 300ms ease-out;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        width: 28px;
                                        height: 28px;
                                        background-color: $color-black;
                                        border-radius: 30px;
                                        cursor: pointer;
                                        pointer-events: all;
                                        transition: all 300ms ease-out;
                                        transform: scale(0.8);
                                    }
                                    &.Article_point_active {
                                        z-index: 1000;
                                        &:after {
                                            transform: scale(1);
                                            background-color: $color-brand;
                                            z-index: 1000;
                                        }
                                    }
                                }
                                &.Article_overlay_active {
                                    opacity: 1;
                                }
                            }
                            &.Article_transition {
                                transition: transform 300ms ease-out;
                                .Article_overlay,
                                .Article_point {
                                    transition: all 300ms ease-out;
                                }
                            }
                        }
                    }
                }
                &.Article_animate .Article_container {
                    transition: all 300ms ease-out;
                }
            }
            .Article_lock {
                position: absolute;
                width: 60px;
                height: 35px;
                left: 20px;
                bottom: 20px;
                pointer-events: none;
            }
            .Article_navigation {
                position: absolute;
                width: 60px;
                height: 40px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                display: none;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 8px auto;
                @media only screen and (min-width: 769px) {
                    display: block;
                }
                &.Article_previous {
                    left: -60px;
                    background-image: url('/images/chevron-left.svg');
                }
                &.Article_next {
                    right: -60px;
                    background-image: url('/images/chevron-right.svg');
                }
            }
            .Article_indicators {
                position: absolute;
                display: flex;
                left: 50%;
                bottom: 20px;
                transform: translateX(-50%);
                .Article_indicator {
                    position: relative;
                    width: 20px;
                    height: 4px;
                    background-color: #dddddd;
                    margin: 0 3px;
                    &.Article_active {
                        background-color: $color-black;
                    }
                }
            }
        }
        .Article_desktop {
            position: absolute;
            width: calc(50% - 30px);
            height: 100%;
            top: 0;
            right: 0;
            display: none;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            @media only screen and (min-width: 769px) {
                display: block;
            }
            @media only screen and (min-width: 1194px) {
                width: calc(100% - 760px);
            }
            .Article_plain {
                position: relative;
                padding: 60px 60px 60px 0;
                padding-bottom: calc(60px + env(safe-area-inset-bottom));
                h2 {
                    position: relative;
                    font-size: 15px;
                    margin-bottom: 20px;
                    line-height: 1.2em;
                    font-weight: 400;
                    color: $color-text-dark;
                    &.Article_highlight {
                        padding-left: 30px;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 15px;
                            height: 15px;
                            top: 2px;
                            left: 0;
                            background-color: $color-brand;
                            border-radius: 100%;
                        }
                    }
                }
                hr {
                    position: relative;
                    margin: 20px 0;
                    padding: 0;
                    height: 1px;
                    background-color: #dddddd;
                    border: 0;
                }
                .Article_author {
                    margin-top: 20px;
                    background-color: $color-background;
                    padding: 15px 20px;
                    p {
                        line-height: 1.4em;
                        color: $color-text-medium;
                        opacity: 0.75;
                    }
                }
            }
        }
        .Article_textual {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            font-size: 13px;
            pointer-events: none;
            @media only screen and (min-width: 769px) {
                display: none;
            }
            h2 {
                position: relative;
                font-size: 15px;
                margin-bottom: 20px;
                line-height: 1.2em;
                font-weight: 400;
                color: $color-text-dark;
                &.Article_highlight {
                    padding-left: 30px;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        top: 2px;
                        left: 0;
                        background-color: $color-brand;
                        border-radius: 100%;
                    }
                }
            }
            .Article_author {
                margin-top: 20px;
                background-color: $color-background;
                padding: 15px 20px;
                p {
                    line-height: 1.4em;
                    color: $color-text-medium;
                    opacity: 0.75;
                }
            }
        }
    }
    .Article_loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-white;
        @keyframes pulse {
            0% {
                background-color: #eeeeee;
            }
            50% {
                background-color: #dddddd;
            }
            100% {
                background-color: #eeeeee;
            }
        }
        .Article_visual {
            position: absolute;
            width: 100%;
            padding-bottom: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.025);
            @media only screen and (min-width: 769px) {
                width: calc(50% - 90px);
                padding-bottom: calc(50% - 90px);
                left: 60px;
                top: 60px;
            }
            @media only screen and (min-width: 1194px) {
                width: 640px;
                padding-bottom: 640px;
            }
            .Article_lock {
                position: absolute;
                width: 35px;
                height: 35px;
                background-color: #eeeeee;
                border-radius: 35px;
                left: 20px;
                bottom: 20px;
                animation: pulse 2s infinite;
            }
        }
        .Article_textual {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 60px 40px;
            box-shadow: 0 -4px 40px rgba(0, 0, 0, 0.05);
            transform: translateY(100vw);
            @media only screen and (min-width: 769px) {
                position: absolute;
                width: calc(50% - 30px);
                height: 100%;
                top: 0;
                right: 0;
                left: auto;
                transform: none;
                box-shadow: none;
                padding: 60px 60px 60px 0;
            }
            @media only screen and (min-width: 1194px) {
                width: calc(100% - 760px);
                right: 0;
                left: auto;
            }
            h2 {
                font-size: 15px;
                margin-bottom: 20px;
                line-height: 1.2em;
                font-weight: 400;
                color: transparent;
                @media only screen and (min-width: 769px) {
                    margin-bottom: 45px;
                }
            }
            p {
                font-size: 13px;
                line-height: 1.4em;
                color: transparent;
            }
            span {
                background-color: #eeeeee;
                animation: pulse 2s infinite;
            }
        }
    }
    .Article_ntk {
        position: relative;
        margin-bottom: 20px;
        background-color: #fff5e1;
        padding: 20px;
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
            color: $color-text-medium;
            font-size: 14px;
            &:after {
                content: ':';
            }
        }
        .Article_context {
            margin-top: 20px;
            li {
                line-height: 1.4em;
                color: $color-text-medium;
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
}
