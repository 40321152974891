@import '../shared/variables.scss';

.Message {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    .Message_overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: all 300ms ease-out;
    }
    .Message_holder {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        width: 350px;
        left: 50%;
        .Message_window {
            position: relative;
            background-color: $color-white;
            border-radius: 6px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
            opacity: 0;
            transition: all 300ms ease-out;
            transform: translateY(-100px);
            .Message_body {
                position: relative;
                padding: 30px;
                h2 {
                    font-size: 15px;
                    margin-bottom: 10px;
                    line-height: 1.2em;
                    font-weight: 400;
                    color: $color-text-dark;
                }
                p {
                    font-size: 14px;
                    line-height: 1.4em;
                    color: $color-text-medium;
                    img {
                        display: inline;
                        margin: 0 4px;
                        transform: translateY(4px);
                    }
                }
            }
            .Message_button {
                position: relative;
                height: 60px;
                border-top: 1px solid #dddddd;
                cursor: pointer;
                border-radius: 0 0 6px 6px;
                &:active {
                    background-color: #eeeeee;
                }
                p {
                    line-height: 57px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: $color-text-dark;
                }
            }
        }
    }
    &.Message_active {
        pointer-events: all;
        .Message_overlay {
            opacity: 1;
        }
        .Message_holder .Message_window {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
