@import '../shared/variables.scss';

.Page {
    position: fixed;
    top: env(safe-area-inset-top);
    right: 0;
    bottom: 0;
    left: 0;
    .Page_body {
        position: absolute;
        top: 60px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-white;
        transition: all 300ms ease-out;
        max-width: 1320px;
        margin: 0 auto;
        .Page_content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 20px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
    .Page_menu {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 60px;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transform: translateY(50%);
        pointer-events: none;
        transition: all 300ms ease-out;
        ul {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateY(-25%);
            li {
                text-align: center;
                a {
                    height: 40px;
                    display: block;
                    line-height: 40px;
                    font-size: 16px;
                    color: $color-text-dark;
                    font-weight: 400;
                }
                .Page_locale {
                    position: relative;
                    height: 16px;
                    width: 24px;
                    margin-top: 15px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        margin: 0;
                        display: flex;
                    }
                }
            }
        }
        .Page_version {
            position: absolute;
            width: 100%;
            bottom: env(safe-area-inset-bottom);
            left: 0;
            padding: 20px;
            p {
                font-size: 13px;
                text-align: center;
                color: $color-text-light;
            }
        }
    }
    .Page_header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 60px;
        background-color: $color-white;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
        transition: box-shadow 300ms ease-out;
        &:before {
            content: '';
            position: absolute;
            top: -100vh;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color-white;
        }
        .Page_navigation {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            .Page_title {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transition: all 300ms ease-out;
                h1 {
                    position: absolute;
                    top: 0;
                    right: 25%;
                    bottom: 0;
                    left: 25%;
                    line-height: 60px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.Page_initial {
                        color: transparent;
                        background-image: url('/images/logo.svg');
                        background-repeat: no-repeat;
                        background-size: auto 32px;
                        background-position: center center;
                    }
                }
            }
            .Page_direction {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 60px;
                cursor: pointer;
                transition: transform 300ms ease-out;
                .Page_one {
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    background-color: $color-black;
                    border-radius: 2px;
                    top: 26px;
                    left: 20px;
                    transition: all 300ms ease-out;
                }
                .Page_two,
                .Page_three {
                    position: absolute;
                    width: 14px;
                    height: 2px;
                    background-color: $color-black;
                    border-radius: 2px;
                    top: 32px;
                    left: 20px;
                    transition: all 300ms ease-out;
                }
                &.Page_back {
                    .Page_one {
                        top: 30px;
                        width: 20px;
                    }
                    .Page_two {
                        width: 8px;
                        top: 33px;
                        transform: rotate(45deg);
                    }
                    .Page_three {
                        width: 8px;
                        top: 27px;
                        transform: rotate(-45deg);
                    }
                }
            }
            .Page_action {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(calc(100% - 60px));
                transition: transform 300ms ease-out;
                input {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 0 20px 0 60px;
                    font-size: 16px;
                    cursor: pointer;
                }
                .Page_icon {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    top: 0;
                    left: 0;
                    pointer-events: none;
                    cursor: pointer;
                    .Page_one {
                        position: absolute;
                        top: 37px;
                        left: 34px;
                        transform: rotate(45deg);
                        background-color: $color-black;
                        border-radius: 2px;
                        height: 2px;
                        transition: all 300ms ease-out;
                    }
                    .Page_two {
                        position: absolute;
                        border-radius: 100%;
                        width: 16px;
                        height: 16px;
                        top: 22px;
                        left: 22px;
                        transition: all 300ms ease-out;
                    }
                    .Page_three {
                        position: absolute;
                        top: 29px;
                        left: 26px;
                        background-color: $color-white;
                        border-radius: 2px;
                        width: 8px;
                        height: 2px;
                        transition: all 300ms ease-out;
                    }
                    .Page_four {
                        position: absolute;
                        top: 29px;
                        left: 26px;
                        background-color: $color-white;
                        border-radius: 2px;
                        width: 8px;
                        height: 2px;
                        transition: all 300ms ease-out;
                        transform: rotate(90deg);
                    }
                    &.Page_hide {
                        .Page_one {
                            opacity: 0;
                        }
                        .Page_two {
                            opacity: 0;
                        }
                    }
                    &.Page_category {
                        .Page_one {
                            width: 6px;
                        }
                        .Page_two {
                            border: 2px solid $color-black;
                        }
                    }
                    &.Page_article {
                        .Page_one {
                            width: 2px;
                        }
                        .Page_two {
                            border: 8px solid $color-black;
                            transform: scale(1.4);
                        }
                    }
                    &.Page_bookmark {
                        .Page_two {
                            border-color: $color-brand;
                        }
                        .Page_four {
                            transform: rotate(0);
                        }
                    }
                }
                .Page_close {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    height: 60px;
                    padding: 0 20px 0 40px;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    background: linear-gradient(
                        to left,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(255, 255, 255, 1) 75%,
                        rgba(255, 255, 255, 0) 100%
                    );
                    p {
                        font-size: 13px;
                        color: $color-text-light;
                    }
                }
            }
        }
    }
    &.Page_initial .Page_header {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0);
    }
    &.Page_menu {
        .Page_body {
            opacity: 0;
            pointer-events: none;
        }
        .Page_menu {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
        }
    }
    &.Page_search .Page_header .Page_navigation {
        .Page_title {
            transform: translateX(-100%);
            opacity: 0;
        }
        .Page_action {
            transform: translateX(0);
        }
        .Page_direction {
            transform: translateX(-60px);
        }
    }
    &.Page_article .Page_header .Page_navigation .Page_action .Page_icon {
        pointer-events: all;
    }
}
