@import '../shared/variables.scss';

.Drag {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    .Drag_overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        pointer-events: none;
        transition: all 300ms ease-out;
        opacity: 0;
    }
    .Drag_mover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .Drag_element {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: all;
        box-shadow: 0 -4px 40px rgba(0, 0, 0, 0.05);
        transform: translateY(100vw);
        background-color: $color-white;
        transition: all 300ms ease-out;
        .Drag_holder {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 60px;
            height: calc(100% - 100vw - 40px);
            left: 0;
            padding: 60px 40px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            padding-bottom: calc(60px + env(safe-area-inset-bottom));
        }
        .Drag_drag {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 60px;
            cursor: pointer;
            opacity: 1;
            transition: all 300ms ease-out;
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 75%,
                rgba(255, 255, 255, 0) 100%
            );
            &:after {
                content: '';
                position: absolute;
                width: 60px;
                height: 4px;
                background-color: #eeeeee;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 4px;
            }
        }
        .Drag_hint {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: calc(100% - 100vw);
            pointer-events: none;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: calc(100px + env(safe-area-inset-bottom));
                background: linear-gradient(
                    to top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
            }
            p {
                position: absolute;
                bottom: calc(40px + env(safe-area-inset-bottom));
                text-align: center;
                width: 100%;
                color: $color-brand;
                font-weight: 400;
                pointer-events: all;
                span {
                    border-bottom: 1px solid $color-brand;
                }
            }
        }
    }
    &.Drag_open {
        .Drag_overlay {
            opacity: 1;
            pointer-events: all;
        }
        .Drag_mover .Drag_element {
            transform: translateY(60px);
            .Drag_holder {
                height: initial;
            }
        }
        .Drag_hint {
            display: none;
        }
    }
    &.Drag_animating .Drag_mover {
        transition: all 300ms ease-out;
    }
    &.Drag_disabled {
        .Drag_drag {
            opacity: 0;
            cursor: default;
            pointer-events: none;
        }
        .Drag_hint {
            display: none;
        }
    }
}
