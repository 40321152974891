@import '../shared/variables.scss';

.App {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: all;
    background-color: $color-white;
    transition: background-color 300ms ease-out;
}
