$color-white: #ffffff;
$color-black: #111111;
$color-brand: #0e407c;
$color-background: #f6f6f6;
$color-grey: #999999;
$color-text-dark: #111111;
$color-text-medium: #666666;
$color-text-light: #aaaaaa;
$color-green: #2ecc40;
$color-orange: #ff851b;
$color-yellow: #ffdc00;
$color-red: #ff4136;

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}
@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }
    @include optional-at-root(':-moz-placeholder') {
        @content;
    }
    @include optional-at-root('::-moz-placeholder') {
        @content;
    }
    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}
