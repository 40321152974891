@import '../shared/variables.scss';

.Confirm {
    position: relative;
    padding: 20px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    .Confirm_window {
        position: relative;
        max-width: 420px;
        margin: 0 auto;
        .Confirm_form {
            position: relative;
            .Confirm_field {
                position: relative;
                margin-bottom: 10px;
                input,
                select {
                    position: relative;
                    width: 100%;
                    height: 50px;
                    padding: 0 15px;
                    border: 1px solid #dddddd;
                    transition: border-color 250ms ease-out;
                    @include placeholder {
                        color: #999999;
                    }
                    &:focus {
                        border-color: #bbbbbb;
                    }
                    &.Confirm_placeholder {
                        color: #999999;
                    }
                }
            }
            .Confirm_submit {
                position: relative;
                margin: 20px 0;
                button {
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                    height: 50px;
                    background-color: lighten($color-brand, 20%);
                    color: $color-white;
                    font-weight: 600;
                    transition: all 250ms ease-out;
                    &:disabled {
                        opacity: 0.5;
                    }
                    &.Confirm_loading:after {
                        content: '...';
                    }
                }
            }
            .Confirm_link {
                position: relative;
                p {
                    text-align: center;
                    margin-bottom: 10px;
                    line-height: 1.4em;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-weight: 400;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
