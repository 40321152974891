@import '../shared/variables.scss';

.Thanks {
    position: relative;
    padding: 20px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    .Thanks_window {
        position: relative;
        max-width: 420px;
        margin: 0 auto;
        .Thanks_form {
            position: relative;
            .Thanks_status {
                position: relative;
                border-radius: 4px;
                padding: 20px;
                &.Thanks_status_open {
                    color: darken($color-orange, 20%);
                    background-color: lighten($color-orange, 35%);
                }
                &.Thanks_status_canceled {
                    color: darken($color-red, 20%);
                    background-color: lighten($color-red, 30%);
                }
                &.Thanks_status_pending {
                    color: darken($color-orange, 20%);
                    background-color: lighten($color-orange, 35%);
                }
                &.Thanks_status_expired {
                    color: darken($color-red, 20%);
                    background-color: lighten($color-red, 30%);
                }
                &.Thanks_status_failed {
                    color: darken($color-red, 20%);
                    background-color: lighten($color-red, 30%);
                }
                &.Thanks_status_paid {
                    color: darken($color-green, 20%);
                    background-color: lighten($color-green, 45%);
                }
                &.Thanks_status_error {
                    color: darken($color-red, 20%);
                    background-color: lighten($color-red, 30%);
                }
            }
            .Thanks_link {
                position: relative;
                margin-top: 20px;
                p {
                    text-align: center;
                    margin-bottom: 10px;
                    line-height: 1.4em;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-weight: 400;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
