@import '../shared/variables.scss';

.Rich {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.6em;
        color: $color-text-dark;
        margin-bottom: 20px;
        font-weight: 600;
    }
    p {
        line-height: 1.4em;
        color: $color-text-medium;
        margin-bottom: 20px;
    }
    a {
        color: $color-text-dark;
    }
    b,
    strong {
        font-weight: 400;
        color: $color-text-dark;
    }
    i,
    em {
        font-style: italic;
    }
    blockquote {
        border-left: 3px solid #dddddd;
        padding-left: 10px;
        margin-bottom: 20px;
        p {
            color: lighten($color-text-dark, 20%);
        }
    }
    code {
        margin-bottom: 20px;
        background: #eeeeee;
        color: lighten($color-text-dark, 20%);
        page-break-inside: avoid;
        font-family: monospace;
        line-height: 1.2;
        max-width: 100%;
        overflow: auto;
        padding: 1em 1.5em;
        display: block;
        word-wrap: break-word;
        padding: 10px;
    }
    ul {
        margin-bottom: 20px;
        list-style: disc outside;
        margin-left: 1.2em;
        li {
            line-height: 1.4em;
            color: $color-text-medium;
        }
    }
    ol {
        margin-bottom: 20px;
        list-style: decimal outside;
        margin-left: 1.2em;
        li {
            line-height: 1.4em;
            color: $color-text-dark;
        }
    }
    img {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }
    table {
        width: 100%;
        table-layout: fixed;
        overflow-wrap: break-word;
        margin-bottom: 20px;
        tr {
            th,
            td {
                border: 1px solid #dddddd;
                padding: 6px;
                text-align: left;
            }
            th {
                font-weight: 400;
                background-color: lighten(#eeeeee, 4%);
            }
            &:last-child {
                th,
                td {
                    border-bottom: 2px solid #dddddd;
                }
            }
        }
    }
    *:last-child {
        margin-bottom: 0;
    }
}
